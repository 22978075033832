// TODO remove when gamescom ad ends
import React, { useState } from "react";
import {
  Typography,
  Paper,
  Fab,
  CircularProgress,
  makeStyles,
  TextField
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import characterGood from "../images/ananas.png";
import characterEvil from "../images/pineEvil.png";
import adImage from "../images/ad2.png";
import logo from "../images/logo-popularity.svg";
import SEO from "../components/seo";

const useStyles = makeStyles(theme => ({
  add: {
    marginTop: theme.spacing(4),
    height: "auto",
    width: "100%"
  },
  root: {
    transition: "background-color 0.5s",
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1
  },
  backgroundBottom: {
    transition: "background-color 0.5s",
    height: theme.spacing(16),
    width: "100%",
    marginTop: -theme.spacing(8),
    borderTopRightRadius: "75%"
  },
  backgroundTop: {
    transition: "background-color 0.5s",
    height: theme.spacing(16),
    width: "100%",
    marginBottom: -theme.spacing(8),
    transform: "skewY(-2deg)",
    transformOrigin: "top left"
  },
  logo: {
    height: theme.spacing(32),
    maxWidth: theme.spacing(32),
    width: "100%",
    margin: `${theme.spacing(1)}px 0px ${theme.spacing(4)}px 0px`,
    alignSelf: "center"
  },
  noticeContainer: {
    display: "flex",
    marginBottom: theme.spacing(3)
  },
  noticeAvatar: {
    height: theme.spacing(12),
    maxWidth: theme.spacing(12),
    marginLeft: theme.spacing(1)
  },
  noticeBubble: {
    backgroundColor: "#fff",
    flex: 1,
    padding: theme.spacing(3)
  },
  block: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: 480,
    width: "100%",
    zIndex: 1
  },
  inputForm: {
    minHeight: theme.spacing(6),
    backgroundColor: "#fff",
    display: "flex",
    paddingLeft: theme.spacing(3),
    width: `calc(100%- ${theme.spacing(3)}px)`,
    borderRadius: theme.spacing(4)
  },
  emailField: {
    alignSelf: "center"
  },
  inputLabel: {
    paddingLeft: theme.spacing(3),
    color: "#fff"
  },
  spinner: {
    color: theme.palette.secondary.main,
    marginLeft: -theme.spacing(6),
    zIndex: 2
  },
  thanks: {
    alignSelf: "center"
  }
}));

const url =
  "https://games.us3.list-manage.com/subscribe/post?u=77a494354cb05eb7d4bc85b30&amp;id=9d56afea84";

function Popularity() {
  const [email, setEmail] = useState();

  const classes = useStyles();

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div
          className={classes.root}
          style={{
            backgroundColor: status === "success" ? "#F4047A" : "#00adb2"
          }}
        >
          <SEO title={"Popularity"} />
          <div
            className={classes.backgroundTop}
            style={{
              backgroundColor: status === "success" ? "#2D0F20" : "#00e2e3"
            }}
          />
          <div className={classes.content}>
            <div className={classes.block}>
              <img src={logo} className={classes.logo} />
              <div className={classes.noticeContainer}>
                <Paper className={classes.noticeBubble}>
                  <Typography gutterBottom>
                    Join our newsletter if you want to recieve more information
                    about future updates and releases.
                  </Typography>
                  <Typography>
                    We love to stay in touch with you to make our new social
                    network an amazing experience for all of us!
                  </Typography>
                </Paper>
                <img
                  src={status === "success" ? characterEvil : characterGood}
                  className={classes.noticeAvatar}
                />
              </div>
              <Typography className={classes.inputLabel} gutterBottom>
                E-Mail
              </Typography>
              <div className={classes.inputForm}>
                {status !== "success" && (
                  <React.Fragment>
                    <TextField
                      error={status === "error" && "Please check you input"}
                      fullWidth
                      className={classes.emailField}
                      onChange={event => setEmail(event.target.value)}
                    />
                    <Fab
                      variant={"outlined"}
                      color={"secondary"}
                      disabled={
                        !email ||
                        email === "" ||
                        (email && !email.includes("@")) ||
                        status === "sending"
                      }
                      onClick={() => subscribe({ EMAIL: email })}
                    >
                      <Send />
                    </Fab>
                    {status === "sending" && (
                      <CircularProgress size={48} className={classes.spinner} />
                    )}
                  </React.Fragment>
                )}
                {status === "success" && (
                  <Typography className={classes.thanks}>
                    Thanks for joining
                  </Typography>
                )}
              </div>

              <img src={adImage} className={classes.add} />
            </div>

            <div
              className={classes.backgroundBottom}
              style={{
                backgroundColor: status === "success" ? "#fff" : "#003d42"
              }}
            />
          </div>
        </div>
      )}
    />
  );
}

export default Popularity;
